import React from "react";
import styles from "./LangSelector.module.css";

const LangSelector = ({ searchParams, selectedLanguage }) => {
    return (
        <div>
            <div className={styles.flexCenter}>
                <select className={styles.select} onChange={(e) => {
                    searchParams.set("lang", e.target.value);
                    window.location.search = searchParams.toString();
                }} value={selectedLanguage}>
                    <option value="sv" >Swedish</option>
                    <option value="en" >English</option>
                    <option value="no" >Norwegian</option>
                    <option value="fi" >Finnish</option>
                    <option value="de" >German</option>
                    <option value="es" >Spanish</option>
                    <option value="fr" >French</option>
                    <option value="ar" >Arabic</option>
                    <option value="tr" >Turkish</option>
                    <option value="pl" >Polish</option>
                    <option value="fa" >فارسی</option>
                </select>
            </div>
        </div>
    );
}

export default LangSelector;