import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_SV } from "./sv/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            sv: {
                translation: TRANSLATIONS_SV,
            },
            fa: {
                translation: TRANSLATIONS_EN,
            },
            no: {
                translation: TRANSLATIONS_EN,
            },
            fi: {
                translation: TRANSLATIONS_EN,
            },
            de: {
                translation: TRANSLATIONS_EN,
            },
            fr: {
                translation: TRANSLATIONS_EN,
            },
            ar: {
                translation: TRANSLATIONS_EN,
            },
            es: {
                translation: TRANSLATIONS_EN,
            },
            tr: {
                translation: TRANSLATIONS_EN,
            },
            pl: {
                translation: TRANSLATIONS_EN,
            },
        },
    });

i18n.changeLanguage("en")
    .then((r) => {console.log("lang set to", r)});